// Custom Styles Table Of Contents
// 1. Masonry Styles
// 2. Resume Styles 
// 3. Dark Mode
// 4. Random Classes

//===============================

// 1. masonry styles 

.grid {
	width:100%;
}
/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}
.grid-item {
  float: left;
}
.grid-item img {
  display: block;
  width: 100%;
}
.grid-sizer,
.grid-item {
	width: calc(50% - 7.5px);
}
.grid-doublewide {
	width:100%;
}
.gutter-sizer {
	width:15px;
}
@media only screen and (max-width: 640px) {
  .grid-sizer,
  .grid-item {
    width: 100%;
  }
  .gutter-sizer {
  	width:1px;
  }
}

// Resume Styles

.resume {
}
.resume h2 {
	line-height: 3.5rem;
}
.resume h3 {
	font-size: 1.5rem;
	line-height: 2.5rem;
}
.resume h4, .resume h5 {
	font-size: 1.1rem;
	line-height: 2rem;
		font-weight: normal;
			opacity: 0.8;
}
.resume h5 {

	font-style: italic;

}
.resume ul {
	line-height: 2rem;
}
// Dark Mode

.darkmode, .darkmode>div>p, .darkmode>div>figcaption, .darkmode>div>h1, .darkmode>div>h2, .darkmode>div>h3, .darkmode>div>h4, .darkmode>div>h5, .darkmode>div>h6{
	color: $light-gray;
	background-color: $black;
}

// Blog styles
.blog, .blank {
	background-color: $black;
}
div>.blogcontent{}

// ID Styles
#subfooter {
	background-color: gray;
}
p.date {
	font-size: .8rem;
	color: $dark-gray;
	line-height: 1rem;
}

.massivenumber {
	font-size: 10rem;
}
.darkbackground {
	background-color: #333333;
}

.logo { 
	padding: 30px;
  display: table-cell;
  vertical-align: middle;
  background-color: #333333;
}
.enablerimage {
	display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 100px;
}
figcaption,caption {
	font-size: .8rem;
}

// Portfolio Styles
.portfolio-item, .portfolio-item h2, .portfolio-item p{
	color: $medium-gray;
	background-color: #0c0c0c;
}

.titleOverImage-container {
	position: relative;
	text-align: center;
	color: $white;
	background-color: rgba(0, 0, 0, 0.6);
	height:200px;
  	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position:50% 50%;
	background-clip: padding-box;
}
// Random Classes
.centered {
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.titleOverImage {
position: relative;
padding: 0;
margin: 0;
}
.titleOverImage img {
display: block;
max-width: 100%;
min-height:9.5rem;
height: auto;
border-radius: $global-radius;
}
.white {
	color: $white;
}
.titleOverImage:after {
content: "";
position: absolute;
display: block;
left: 0;
top: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 10, 4, 0) 50px, rgba(10, 10, 4, 1.0) 100%) repeat 0 0;
z-index: 1;
border-radius: $global-radius;
-webkit-transition: background 2s; /* Safari prior 6.1 */
transition: background .5s;
}
.titleOverImage:hover:after {
	background: rgba(200, 0, 0, .6) linear-gradient(to bottom, rgba(200, 0, 0, 0) 1px, rgba(200, 0, 0, 1.0) 100%) repeat 0 0;
}
.titleOverImage-text {
display: block;
position: absolute;
width: 100%;
color: #fff;
left: 0;
bottom: 0;
padding: $global-padding;
font-size: 1.8rem;
line-height: 2.2rem;
font-weight: 700;
z-index: 2;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}
.imageheading {
	max-width: 100%;
	min-height:20rem;
	position: relative;
	text-align: center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position:50% 50%;
	background-clip: padding-box;
}
.taggrid{
	padding-bottom: 1em; padding-left: 1em;
}
.titleOverImage-text a {
	color:#fff;
}
.top-padding {
	padding-top: $global-padding;
}
.bottom-padding {
	padding-bottom: $global-padding;
}

.mini {
	font-size: .8em;
}

a.rollover-button,p.rollover-button{
	border-radius: 3px;
	padding:8px;
}
a.rollover-button:hover,p.rollover-button:hover {
	background-color: $primary-color;
	color: $white;
	transition-property: background-color;
  transition-duration: .2s;
	-webkit-transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
   -moz-transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
     -o-transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
        transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950); /* easeInOutSine */
}

.Forms__FormsViralityContainer-sc-11vy283-0 {display:none}
.iCJCmn {display: none;}
/* Small only */
@media screen and (max-width: 39.9375em) {
	.small-top-padding {
		padding-top: .8rem;
	}
	.imageheading {
	min-height:13rem;
	}
}
/* Medium and up */
@media screen and (min-width: 40em) {
	.medium-top-padding {
		padding-top: .8rem;
	}
}
/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {
	.large-top-padding {
		padding-top: .8rem;
	}
}
/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}

